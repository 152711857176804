import { SiteService } from './../../services/site.service';
import { Evento } from './../../models/evento.model';
import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-eventos',
  templateUrl: './eventos.component.html',
  styleUrls: ['./eventos.component.scss']
})
export class EventosComponent implements OnInit {

  eventos: Evento[] = [];
  loading: boolean = true;
  buscarEventosSubscription: Subscription

  constructor(
    public siteService: SiteService
  ) { }

  ngOnInit() {
    this.buscarEventos();
  }

  buscarEventos() {
    this.loading = true;
    this.buscarEventosSubscription = this.siteService.buscarEventos(-99, -99)
      .subscribe((res: Evento[]) => {
        this.eventos = res;
        this.loading = false;
      }, e => this.loading = false);
  }

}
