import { ContatoGeral } from './../models/contato-geral.model';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {

  apiUrl: string = 'https://api.monasteriopizzaria.com.br';
  contatoGeral: ContatoGeral = new ContatoGeral();

  constructor() { }

  setContatoGeralStorage(contatoGeral: ContatoGeral) {
    localStorage.setItem('contatoGeral_monasterio', JSON.stringify(contatoGeral));
  }

  getContatoGeralStorage() {
    return JSON.parse(localStorage.getItem('contatoGeral_monasterio'));
  }
}
