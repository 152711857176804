import { LoadingComponent } from "./components/loading/loading.component";
import { WhatsappComponent } from "./components/whatsapp/whatsapp.component";
import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HomeComponent } from "./pages/home/home.component";
import { HeaderComponent } from "./components/header/header.component";
import { FooterComponent } from "./components/footer/footer.component";
import { SobreComponent } from "./pages/sobre/sobre.component";
import { EventosComponent } from "./pages/eventos/eventos.component";
import { EventosInternoComponent } from "./pages/eventos-interno/eventos-interno.component";

// imports
import { NgxMaskModule } from "ngx-mask";
import { AlertModule } from "ngx-alerts";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HttpClientModule } from "@angular/common/http";
import { FormsModule } from "@angular/forms";
import { ServicosComponent } from "./pages/servicos/servicos.component";
import { CardapioComponent } from "./pages/cardapio/cardapio.component";
import { ContatoComponent } from "./pages/contato/contato.component";
import { PedidosOnlineComponent } from "./pages/pedidos-online/pedidos-online.component";
import { CardapioInternoComponent } from "./pages/cardapio-interno/cardapio-interno.component";

import { LOCALE_ID } from "@angular/core";
import localePt from "@angular/common/locales/pt";
import { registerLocaleData } from "@angular/common";
registerLocaleData(localePt);

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    SobreComponent,
    EventosComponent,
    EventosInternoComponent,
    ServicosComponent,
    CardapioComponent,
    ContatoComponent,
    PedidosOnlineComponent,
    CardapioInternoComponent,
    WhatsappComponent,
    LoadingComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    AlertModule.forRoot({
      maxMessages: 5,
      timeout: 4000,
      positionX: "right",
      positionY: "bottom",
    }),
    NgxMaskModule.forRoot(),
  ],
  providers: [{ provide: LOCALE_ID, useValue: "pt-BR" }],
  bootstrap: [AppComponent],
})
export class AppModule {}
