import { Evento } from './evento.model';
export class Reserva {

    id: number;
    evento: Evento;
    nome: string;
    telefone: string;
    data: Date;
    horario: Date;
    numeroConvidados: number;
    email: string;
    observacao?: string = '';
    verificado?: string = '';
    [x: string]: any;

    constructor(obj?) {
        Object.assign(this);
    }
}