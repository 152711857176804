<footer class="footer">
    <div class="container">
        <div class="footer-wrapper">
            <div class="footer-contact" *ngIf="global.contatoGeral">
                <ng-container *ngIf="global.contatoGeral.telefone.length == 10">
                    {{global.contatoGeral.telefone | mask: '(00) 0000-0000'}}</ng-container>
                    <ng-container *ngIf="global.contatoGeral.telefone.length >= 11">
                        {{global.contatoGeral.telefone | mask: '(00) 0 0000-0000'}}</ng-container> <br />
                {{global.contatoGeral.rua}}, {{global.contatoGeral.numeroEndereco}} <br />
                CEP: {{global.contatoGeral.cep | mask: '00000-000'}} | {{global.contatoGeral.cidade}} -
                {{global.contatoGeral.estado}} <br />
            </div>
            <ul class="footer-social">
                <li class="footer-social__item" *ngIf="global.contatoGeral.linkFacebook">
                    <a [href]="global.contatoGeral.linkFacebook" target="_blank" class="footer-social__link">
                        <i class="fab fa-facebook-square"></i>
                    </a>
                </li>
                <li class="footer-social__item" *ngIf="global.contatoGeral.linkInstagram">
                    <a [href]="global.contatoGeral.linkInstagram" target="_blank" class="footer-social__link">
                        <i class="fab fa-instagram"></i>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</footer>