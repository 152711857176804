import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pedidos-online',
  templateUrl: './pedidos-online.component.html',
  styleUrls: ['./pedidos-online.component.scss']
})
export class PedidosOnlineComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
