<section class="cardapio">
    <div class="container">
        <div class="caradapio-wrapper row">
            <button class="cardapio-box col50" [style.background-image]="'url(assets/pizza-salgada.jpg)'"
                [routerLink]="['/cardapio', 'salgada']">
                <div class="cardapio-box-content">
                    <h2>PIZZAS<br />SALGADAS</h2>
                    <span>ACESSE PARA VER OS SABORES</span>
                </div>
            </button>
            <button class="cardapio-box col50" [style.background-image]="'url(assets/pizza-doce.jpg)'"
                [routerLink]="['/cardapio', 'doce']">
                <div class="cardapio-box-content">
                    <h2>PIZZAS<br />DOCES</h2>
                    <span>ACESSE PARA VER OS SABORES</span>
                </div>
            </button>
        </div>
    </div>
</section>