import { GlobalService } from './../../services/global.service';
import { Component, OnInit, HostListener } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  openMenu: boolean = false;
  active: boolean = false;

  @HostListener('window:scroll', [])
  handleScroll() {
    const windowScroll = window.pageYOffset;
    if (windowScroll >= 228) {
      this.active = true;
    } else if (windowScroll <= 150) {
      this.active = false;
    }
  }

  constructor(
    public global: GlobalService
  ) { }

  ngOnInit() {
  }

  toggleMenu() {
    this.openMenu = !this.openMenu
  }


}
