import { SiteService } from './../../services/site.service';
import { Component, OnInit } from '@angular/core';
import { Servico } from 'src/app/models/servico.model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-servicos',
  templateUrl: './servicos.component.html',
  styleUrls: ['./servicos.component.scss']
})
export class ServicosComponent implements OnInit {

  servicos: Servico[] = [];
  buscarServicosSubscription: Subscription;
  loading: boolean = true;

  constructor(
    public siteService: SiteService
  ) { }

  ngOnInit() {
    this.buscarServicos();
  }

  ngOnDestroy() {
    this.buscarServicosSubscription.unsubscribe();
  }

  buscarServicos() {
    this.loading = true;
    this.buscarServicosSubscription = this.siteService.buscarServicos(-99, -99)
      .subscribe((res: Servico[]) => {
        this.servicos = res;
        this.loading = false;
      }, e => this.loading = false);
  }



}
