import { Banner } from './../models/banner.model';
import { Reserva } from './../models/reserva.model';
import { Evento } from './../models/evento.model';
import { Mensagem } from './../models/mensagem.model';
import { ContatoGeral } from './../models/contato-geral.model';
import { CadastroGeral } from './../models/cadastro-geral.model';
import { ApiService } from './api.service';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse, HttpParams, HttpRequest } from '@angular/common/http';
import { GlobalService } from './global.service';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SiteService {

  constructor(
    public api: ApiService,
    public global: GlobalService,
    public http: HttpClient
  ) { }

  buscarServicos(numeroPagina: number, registrosPorPagina?: number): Observable<any> {
    return this.api.get(`/site/servico/buscar?numeroPagina=${numeroPagina}&registrosPorPagina=${registrosPorPagina}`);
  }

  buscarEventos(numeroPagina: number, registrosPorPagina?: number): Observable<any> {
    return this.api.get(`/site/evento/buscar?numeroPagina=${numeroPagina}&registrosPorPagina=${registrosPorPagina}`);
  }

  buscarServico(slug: string) {
    return this.api.get(`/site/servico/buscar?slug=${slug}`);
  }

  buscarEvento(slug: string) {
    return this.api.get(`/site/evento/buscar?slug=${slug}`);
  }

  buscarBanner(): Observable<Banner> {
    return this.api.get('/site/banner/buscar');
  }

  buscarCadastroGeral(): Observable<CadastroGeral> {
    return this.api.get('/site/cadastroGeral/buscar');
  }

  buscarContatoGeral(): Observable<ContatoGeral> {
    return this.api.get('/site/contatoGeral/buscar');
  }

  buscarPizzas(tipo: string, numeroPagina: number, registrosPorPagina?: number): Observable<any> {
    return this.api.get(`/site/pizza/${tipo}/buscar?numeroPagina=${numeroPagina}&registrosPorPagina=${registrosPorPagina}`);
  }

  inserirContatoMensagem(mensagem: Mensagem): Observable<any> {
    return this.api.post('/site/contatoMensagem/inserir', mensagem);
  }

  inserirTrabalheConosco(mensagem: Mensagem): Observable<any> {
    return this.api.post('/site/contatoMensagem/trabalheConosco', mensagem);
  }

  inscreverEvento(reserva: Reserva) {
    return this.api.post('/site/evento/inscrever', reserva);
  }

  postFile(fileToUpload: File, url: string, fileName: string): Observable<any> {
    const formData: FormData = new FormData();
    formData.append(fileName, fileToUpload, fileToUpload.name);

    const options = {
      method: 'POST',
      httpParams: new HttpParams()
    }

    const req = new HttpRequest(options.method, this.global.apiUrl + url, formData, {
      params: options.httpParams,
      reportProgress: true,
    });

    return this.http.request(req).pipe(map(r => r), catchError((e) => this.handleError(e)))
  }

  handleError(error: HttpErrorResponse) {
    return throwError(error);
  }
}
