<div class="nav-bottom">
  <div class="popup-whatsapp fadeIn" [class.is-active-whatsapp-popup]="isOpen">
    <div class="content-whatsapp-top">
      <button type="button" class="closePopup" (click)="isOpen = !isOpen">
        <i class="material-icons icon-font-color">close</i>
      </button>
      <p>Olá, como eu posso te ajudar? 😊</p>
    </div>
    <div class="content-whatsapp -bottom">
      <input
        class="whats-input"
        id="whats-in"
        type="text"
        [(ngModel)]="message"
        placeholder="Escreva sua mensagem..."
      />
      <button
        class="send-msPopup"
        id="send-btn"
        type="button"
        (click)="sendMessage()"
      >
        <i class="material-icons icon-font-color--black">send</i>
      </button>
    </div>
  </div>
  <button
    type="button"
    id="whats-openPopup"
    class="whatsapp-button"
    (click)="isOpen = !isOpen"
  >
    <img class="icon-whatsapp" src="assets/whatsapp.png" />
  </button>
  <div class="circle-anime"></div>
</div>
