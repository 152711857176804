import { CadastroGeral } from './../../models/cadastro-geral.model';
import { SiteService } from './../../services/site.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sobre',
  templateUrl: './sobre.component.html',
  styleUrls: ['./sobre.component.scss']
})
export class SobreComponent implements OnInit {

  cadastroGeral: CadastroGeral = new CadastroGeral();

  constructor(
    public siteService: SiteService
  ) { }

  ngOnInit() {
    this.siteService.buscarCadastroGeral().subscribe((res: CadastroGeral) => {
      this.cadastroGeral = res;
    });
  }



}
