import { Reserva } from './../../models/reserva.model';
import { LoadingService } from './../../services/loading.service';
import { AlertService } from 'ngx-alerts';
import { HelperService } from './../../services/helper.service';
import { NgForm } from '@angular/forms';
import { Servico } from './../../models/servico.model';
import { Evento } from './../../models/evento.model';
import { SiteService } from './../../services/site.service';
import { ApiService } from './../../services/api.service';
import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-eventos-interno',
  templateUrl: './eventos-interno.component.html',
  styleUrls: ['./eventos-interno.component.scss']
})
export class EventosInternoComponent implements OnInit {

  evento: Evento = new Evento();
  reserva: Reserva = new Reserva();
  buscarEventoSubscription: Subscription;
  loading: boolean = true;

  servicos: Servico[] = [];
  buscarServicoesSubscription: Subscription;

  phoneMask: string = '(00) 0000-00009';

  constructor(
    public siteService: SiteService,
    public route: ActivatedRoute,
    public helper: HelperService,
    public alertService: AlertService,
    public loadingService: LoadingService
  ) { }

  ngOnInit() {
    this.route.params.subscribe(param => this.buscarEvento(param.slug));
    this.buscarServicos();
  }

  ngOnDestroy() {
    this.buscarEventoSubscription.unsubscribe();
    this.buscarServicoesSubscription.unsubscribe();
  }

  buscarEvento(slug: string) {
    this.loading = true;
    this.buscarEventoSubscription = this.siteService.buscarEvento(slug)
      .subscribe((res: Evento) => {
        this.evento = res;
        this.reserva.evento = res;
        this.loading = false;
      }, e => this.loading = false);
  }

  buscarServicos() {
    this.buscarServicoesSubscription = this.siteService.buscarServicos(-99, -99)
      .subscribe((res: Servico[]) => this.servicos = res);
  }

  changePhoneMask(phone) {
    let val = phone;
    if (val.replace(/\D/g, '').substring(0, 4) == "0800") {
      this.phoneMask = '0000 000 0000';
    } else {
      if (val.replace(/\D/g, '').length === 11) {
        this.phoneMask = '(00) 0 0000-0000';
      } else {
        this.phoneMask = '(00) 0000-00009';
      }
    }
  }

  changeServicos(e) {
    console.log(e);
  }

  inscreverEvento(f: NgForm) {

    if (f.invalid) {
      this.helper.formMarkAllTouched(f);
      this.alertService.danger('Preencha os campos em vermelho, por favor.');
      return;
    }

    this.loadingService.present();

    this.reserva.data = this.helper.moment(this.reserva.data, 'DD/MM/YYYY').format();
    this.reserva.horario = this.helper.moment(this.reserva.horario, 'HH:mm').format();

    this.siteService.inscreverEvento(this.reserva)
      .subscribe((res: any) => {
        this.alertService.success('Reserva efetuada com sucesso, verifique seu e-mail!');
        f.reset();
        this.loadingService.dismiss();
      }, e => {
        this.reserva.data = this.helper.moment(this.reserva.data).format('DD/MM/YYYY');
        this.reserva.horario = this.helper.moment(this.reserva.horario).format('HH:mm');
        this.alertService.danger('Não foi possível fazer a reserva. Tente novamente, por favor.');
        this.loadingService.dismiss();
      })
  }

}
