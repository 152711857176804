<header class="header" [class.active]="active">
    <div class="container">
        <nav class="header-nav">
            <div class="top">
                <a class="logo" title="Monastério Pizzaria" routerLink="/">
                    <img src="assets/logo.png" alt="Monastério Pizzaria">
                </a>
                <ul class="header-social">
                    <li class="header-social__item" *ngIf="global.contatoGeral.linkFacebook">
                        <a [href]="global.contatoGeral.linkFacebook" target="_blank" class="header-social__link">
                            <i class="fab fa-facebook-square"></i>
                        </a>
                    </li>
                    <li class="header-social__item" *ngIf="global.contatoGeral.linkInstagram">
                        <a [href]="global.contatoGeral.linkInstagram" target="_blank" class="header-social__link">
                            <i class="fab fa-instagram"></i>
                        </a>
                    </li>
                </ul>
            </div>
            <button class="header-menu-toggle" (click)="toggleMenu()">
                <i class="fas fa-bars"></i>
            </button>
            <ul class="header-menu" [class.active]="openMenu">
                <li class="header-menu__item">
                    <a routerLink="/inicio" class="header-menu__link" title="início" routerLinkActive="active"
                        (click)="toggleMenu()">
                        início
                    </a>
                </li>
                <li class="header-menu__item">
                    <a routerLink="/sobre" class="header-menu__link" title="a casa" routerLinkActive="active"
                        (click)="toggleMenu()">
                        a casa
                    </a>
                </li>
                <li class="header-menu__item">
                    <a routerLink="/cardapio" class="header-menu__link" title="cardápio" routerLinkActive="active"
                        (click)="toggleMenu()">
                        cardápio
                    </a>
                </li>
                <li class="header-menu__item">
                    <a routerLink="/eventos" class="header-menu__link" title="faça seu evento" routerLinkActive="active"
                        (click)="toggleMenu()">
                        faça seu evento
                    </a>
                </li>
                <li class="header-menu__item">
                    <a class="header-menu__link" title="pedidos online" routerLinkActive="active"
                        href="https://pedido.wdelivery.com.br/monasterio-pizzaria-delivery?profile_id=1724">
                        pedidos online
                    </a>
                </li>
                <li class="header-menu__item">
                    <a routerLink="/servicos" class="header-menu__link" title="serviços" routerLinkActive="active"
                        (click)="toggleMenu()">
                        serviços
                    </a>
                </li>
                <li class="header-menu__item hidden-mobile">
                    <span class="header-menu__link">
                        contato
                    </span>
                    <ul class="submenu">
                        <li class="submenu-item">
                            <a routerLink="/contato" class="header-menu__link" title="contato" routerLinkActive="active"
                                (click)="toggleMenu()">
                                entre em contato
                            </a>
                        </li>
                        <li class="submenu-item">
                            <a routerLink="/trabalhe-conosco" class="header-menu__link" title="trabalhe conosco"
                                routerLinkActive="active" (click)="toggleMenu()">
                                trabalhe conosco
                            </a>
                        </li>
                    </ul>
                </li>
                <li class="header-menu__item hidden-desktop">
                    <a routerLink="/contato" class="header-menu__link" title="contato" routerLinkActive="active"
                        (click)="toggleMenu()">
                        Contato
                    </a>
                </li>
                <li class="header-menu__item hidden-desktop">
                    <a routerLink="/trabalhe-conosco" class="header-menu__link" title="serviços"
                        routerLinkActive="active" (click)="toggleMenu()">
                        Trabalhe Conosco
                    </a>
                </li>
            </ul>
            <div class="header-menu-backdrop" (click)="toggleMenu()" [class.active]="openMenu"></div>
        </nav>
    </div>
</header>