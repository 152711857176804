<section class="contato">
    <div class="container">
        <div class="contato-wrapper row">
            <div class="left">
                <h3>Envie-nos uma mensagem, que assim que possível iremos entrar em contato!</h3>
                <div class="radio-group">
                    <h4 class="radio-group__title">
                        Escolha uma das opções
                    </h4>
                    <div class="radio-item">
                        <input type="radio" name="tipo" [(ngModel)]="tipo" (ngModelChange)="onTipoChange($event)"
                            value="T" id="trabalhe-conosco">
                        <label for="trabalhe-conosco">Trabalhe Conosco</label>
                    </div>
                    <div class="radio-item">
                        <input type="radio" name="tipo" [(ngModel)]="tipo" (ngModelChange)="onTipoChange($event)"
                            value="C" id="contato">
                        <label for="contato">Contato</label>
                    </div>
                </div>
                <form class="form" #form="ngForm" (ngSubmit)="enviarMensagem(form)">
                    <div class="form-control form-control--icon">
                        <input type="text" name="nome" placeholder="Digite seu nome" [(ngModel)]="mensagem.nome"
                            required>
                        <i class="fas fa-user"></i>
                    </div>
                    <div class="form-control form-control--icon">
                        <input type="email" [pattern]="helper.emailRegex" name="email" placeholder="Digite seu e-mail"
                            [(ngModel)]="mensagem.email" required>
                        <i class="fas fa-envelope"></i>
                    </div>
                    <div class="form-control form-control--icon">
                        <input type="tel" name="fone" placeholder="Digite seu telefone" [(ngModel)]="mensagem.telefone"
                            required (ngModelChange)="changePhoneMask($event)" [mask]="phoneMask">
                        <i class="fas fa-phone"></i>
                    </div>
                    <div class="form-control form-control--icon" *ngIf="tipo === 'C'">
                        <input type="text" name="assunto" placeholder="Digite um assunto (opcional)"
                            [(ngModel)]="mensagem.assunto">
                        <i class="fas fa-bookmark"></i>
                    </div>
                    <div class="form-control form-control--icon" *ngIf="tipo === 'T'">
                        <input type="file" #inputFile name="curriculo" id="file" accept="application/pdf"
                            (change)="onChangeInputFiles($event.target.files)" required>
                        <i class="fas fa-address-book"></i>
                    </div>
                    <div class="form-control">
                        <textarea name="mensagem" id="mensagem" rows="6" placeholder="Digite uma mensagem"
                            [(ngModel)]="mensagem.mensagem" required></textarea>
                    </div>
                    <button class="btn btn-submit">
                        ENVIAR
                    </button>
                </form>
            </div>
            <div class="right">
                <div #map id="map" class="map"></div>
            </div>
        </div>
    </div>
</section>