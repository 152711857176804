import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-whatsapp',
  templateUrl: './whatsapp.component.html',
  styleUrls: ['./whatsapp.component.scss']
})
export class WhatsappComponent implements OnInit {

  @ViewChild('closePopup', { static: true }) closePopupElement: ElementRef;
  @ViewChild('whatsappButton', { static: true }) whatsappButtonElement: ElementRef;
  @ViewChild('popupWhatsapp', { static: true }) popupWhatsappElement: ElementRef;
  @ViewChild('sendBtn', { static: true }) sendBtnElement: ElementRef;
  message: string;
  phoneNumber: string = '555435387687'
  isOpen: boolean = false;

  constructor() { }

  ngOnInit() {
  }

  popupWhatsApp() {

    let btnClosePopup = this.closePopupElement.nativeElement;
    let btnOpenPopup = this.whatsappButtonElement.nativeElement;
    let popup = this.popupWhatsappElement.nativeElement;

    btnClosePopup.addEventListener("click", () => {
      popup.classList.toggle('is-active-whatsapp-popup')
    })

    btnOpenPopup.addEventListener("click", () => {
      popup.classList.toggle('is-active-whatsapp-popup')
      popup.style.animation = "fadeIn .6s 0.0s both";
    })

    this.sendMessage();

    setTimeout(() => {
      popup.classList.toggle('is-active-whatsapp-popup');
    }, 3000);
  }

  sendMessage() {
    let msg = this.message.replace(/ /g, "%20");
    window.open(`https://wa.me/${this.phoneNumber}?text=${msg}`, '_blank');
  }

  // popupWhatsApp() {

  // }

}
