import { SiteService } from './../../services/site.service';
import { Subscription } from 'rxjs';
import { Pizza } from './../../models/pizza.model';
import { Component, OnInit, HostListener } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-cardapio-interno',
  templateUrl: './cardapio-interno.component.html',
  styleUrls: ['./cardapio-interno.component.scss']
})
export class CardapioInternoComponent implements OnInit {

  slug: string;
  pizzas: Pizza[] = [];
  loading: boolean = true;
  buscarPizzasSubscription: Subscription;
  selectedImage: string;

  active: boolean = false;

  @HostListener('window:scroll', [])
  handleScroll() {
    const windowScroll = window.pageYOffset;
    if (windowScroll >= 228) {
      this.active = true;
    } else if (windowScroll <= 150) {
      this.active = false;
    }
  }

  constructor(
    public route: ActivatedRoute,
    public siteService: SiteService
  ) { }

  ngOnInit() {
    this.route.params.subscribe(param => {
      this.slug = param.slug;
      this.buscarPizzas(param.slug, -99, -99);
    })
  }

  ngOnDestroy() {
    this.buscarPizzasSubscription.unsubscribe();
  }

  buscarPizzas(tipo: string, numeroPagina: number, registrosPorPagina: number) {
    this.loading = true;
    this.buscarPizzasSubscription = this.siteService.buscarPizzas(tipo, numeroPagina, registrosPorPagina)
      .subscribe((res: Pizza[]) => {
        this.pizzas = res;
        this.selectedImage = res[0].imagem;
        this.loading = false;
      }, e => this.loading = false);
  }

  setImage(url: string) {
    this.selectedImage = url;
  }

}
