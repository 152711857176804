<app-header></app-header>

<main>
    <router-outlet></router-outlet>
</main>

<ngx-alerts></ngx-alerts>
<app-footer></app-footer>
<app-whatsapp></app-whatsapp>
<app-loading></app-loading>
