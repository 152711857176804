export class Evento {

    id: number;
    imagemPequena: string = '';
    imagemGrande: string = '';
    titulo: string;
    descricao: string;
    slug: string;
    data: Date = new Date();
    situacao: string = 'A';
    [x: string]: any;

    constructor(obj?) {
        Object.assign(this);
    }
}