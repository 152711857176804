<section class="servicos">
    <div class="container">
        <div class="servicos-wrapper row">
            <div class="servicos-item col33" *ngFor="let s of servicos; let i = index;">
                <div class="servicos-item-header">
                    <div class="servicos-item__image">
                        <img [src]="s.imagemGrande" [alt]="s.titulo">
                    </div>
                    <h2 class="servicos-item__title">{{s.titulo}}</h2>
                </div>
                <div class="servicos-item__content" [innerHtml]="s.descricao"></div>
            </div>
        </div>
    </div>
</section>