<section class="cardapio-interno">
    <div class="container">
        <div class="cardapio-interno-wrapper row">
            <div class="left">
                <a class="back-button" title="Voltar" routerLink="/cardapio">
                    Voltar
                </a>
                <h1 class="cardapio-interno__title">
                    PIZZAS {{slug.toLocaleUpperCase()}}S
                </h1>
                <div class="cardapio-interno__list">
                    <div class="pizza" *ngFor="let pizza of pizzas; let i = index;" (click)="setImage(pizza.imagem)">
                        <div class="pizza-header">
                            <h4 class="pizza__title">
                                {{pizza.sabor}}
                            </h4>
                            <div class="pizza__line"></div>
                            <span class="pizza__price"
                                *ngIf="pizza.valor > 0">{{pizza.valor | currency:'BRL':true}}</span>
                        </div>
                        <div class="pizza-content">
                            <strong>Ingredientes:</strong> {{pizza.ingredientes}}
                        </div>
                    </div>
                </div>
            </div>
            <div class="right" [class.active]="active">
                <div class="cardapio-interno__image">
                    <img [src]="selectedImage || 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT7Ph8c_5z4Ghcoh-cpM8dl82ty8mMLPLxxhIo4KhmIvJ2_c5Ir&s'"
                        alt="Foto de pizza">
                </div>
                <a class="cardapio-interno__pedidos"
                    href="https://pedido.wdelivery.com.br/monasterio-pizzaria-delivery?profile_id=1724">
                    <h4>FAÇA SEU<br />PEDIDO ONLINE</h4>
                    <img src="assets/pedidos-online.jpg" alt="Pedidos online">
                </a>
            </div>
        </div>
    </div>
</section>