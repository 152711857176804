import { Banner } from './../../models/banner.model';
import { SiteService } from './../../services/site.service';
import { ContatoGeral } from './../../models/contato-geral.model';
import { HelperService } from './../../services/helper.service';
import { GlobalService } from './../../services/global.service';
import { Component, OnInit, AfterViewInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, AfterViewInit {

  banner: Banner = new Banner();

  videoUrl: SafeUrl;
  contatoGeral: ContatoGeral = new ContatoGeral();

  constructor(
    public global: GlobalService,
    public helper: HelperService,
    public sanitizer: DomSanitizer,
    public siteService: SiteService
  ) {

  }

  ngOnInit() {
    this.buscarBanner();
  }

  ngAfterViewInit() {
  }

  buscarBanner() {
    this.siteService.buscarBanner().subscribe((res: Banner) => {
      this.banner = res;
      const id = this.helper.getYoutubeId(res.linkVideo);
      this.videoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`https://www.youtube.com/embed/${id}?controls=0&showinfo=0&rel=0&autoplay=1&loop=1&mute=1&iv_load_policy=3&playlist=${id}`);
    })
  }

}
