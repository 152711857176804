export class ContatoGeral {
    id: number;
    latitude: number;
    longitude: number;
    rua: string;
    estado: string;
    cidade: string;
    numeroEndereco: string;
    complemento: string = '';
    linkInstagram?: string = '';
    linkLinkedin?: string = '';
    linkFacebook?: string = '';
    linkYoutube?: string = '';
    imagemYoutube?: string = '';
    email: string;
    emailsAdicionais?: string = '';
    telefone: string = '';
    telefonesAdicionais?: string = '';
    [x: string]: any;

    constructor(obj?) {
        Object.assign(this);
    }
}