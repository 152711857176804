import { GlobalService } from './services/global.service';
import { ContatoGeral } from './models/contato-geral.model';
import { SiteService } from './services/site.service';
import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

declare const gtag: Function; // <------------Important: the declartion for gtag is required!

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  constructor(
    public siteService: SiteService,
    public global: GlobalService,
    private router: Router
  ) {
    /** START : Code to Track Page View using gtag.js */
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        gtag("event", "page_view", {
          page_path: event.urlAfterRedirects,
        });
      });
    /** END : Code to Track Page View  using gtag.js */
    this.siteService.buscarContatoGeral()
      .subscribe((res: ContatoGeral) => {
        this.global.contatoGeral = res;
        this.global.setContatoGeralStorage(res);
      })
  }
}
