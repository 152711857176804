import { LoadingService } from './../../services/loading.service';
import { HelperService } from './../../services/helper.service';
import { SiteService } from './../../services/site.service';
import { Mensagem } from './../../models/mensagem.model';
import { GlobalService } from './../../services/global.service';
import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { AlertService } from 'ngx-alerts';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpProgressEvent } from '@angular/common/http';

declare var google;


@Component({
  selector: 'app-contato',
  templateUrl: './contato.component.html',
  styleUrls: ['./contato.component.scss']
})
export class ContatoComponent implements OnInit, AfterViewInit {

  @ViewChild('map', { static: true }) mapElement: ElementRef;

  mensagem: Mensagem = new Mensagem();

  phoneMask: string = '(00) 0000-00009';
  tipo: string = '';

  file: File;
  progress: number = 0;

  constructor(
    public global: GlobalService,
    public siteService: SiteService,
    public alertService: AlertService,
    public helper: HelperService,
    public loadingService: LoadingService,
    public router: Router
  ) {
    if (this.router.url === '/contato') {
      this.tipo = 'C';
    } else if (this.router.url === '/trabalhe-conosco') {
      this.tipo = 'T';
      this.mensagem.assunto = 'Currículo'
    }
  }

  ngOnInit() { }

  ngAfterViewInit() {
    const contatoGeral = this.global.getContatoGeralStorage();
    this.initMap(contatoGeral.latitude, contatoGeral.longitude);
  }

  enviarMensagem(f: NgForm) {

    if (f.invalid) {
      this.helper.formMarkAllTouched(f);
      this.alertService.danger('Preencha os campos requiridos, por favor!');
      return;
    }

    this.loadingService.present();

    if (this.tipo === 'C') {
      this.siteService.inserirContatoMensagem(this.mensagem)
        .subscribe((res: any) => {
          f.reset();
          this.alertService.success('Sua mensagem foi enviada com sucesso! Aguarde nosso retorno.')
          this.loadingService.dismiss();
        }, e => {
          this.alertService.danger('Não foi possível enviar a mensagem. Tente novamente outra hora.');
          this.loadingService.dismiss()
        })
    } else {
      this.siteService.inserirTrabalheConosco(this.mensagem)
        .subscribe((res: any) => {
          f.reset();
          this.alertService.success('Sua mensagem foi enviada com sucesso! Aguarde nosso retorno.')
          this.loadingService.dismiss();
        }, e => {
          this.alertService.danger('Não foi possível enviar a mensagem. Tente novamente outra hora.');
          this.loadingService.dismiss()
        })
    }
  }

  changePhoneMask(phone) {
    let val = phone;
    if (val.replace(/\D/g, '').substring(0, 4) == "0800") {
      this.phoneMask = '0000 000 0000';
    } else {
      if (val.replace(/\D/g, '').length === 11) {
        this.phoneMask = '(00) 0 0000-0000';
      } else {
        this.phoneMask = '(00) 0000-00009';
      }
    }
  }

  onTipoChange(e) {
    if (e === 'C') {
      this.router.navigate(['/contato']);
    } else if (e === 'T') {
      this.router.navigate(['/trabalhe-conosco']);
    }
  }

  initMap(lat, lng) {
    const latLng = new google.maps.LatLng(lat, lng);

    const mapOptions = {
      center: latLng,
      zoom: 17,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      marker: latLng,
      draggable: true,
      styles: [
        {
          "featureType": "water",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#e9e9e9"
            },
            {
              "lightness": 17
            }
          ]
        },
        {
          "featureType": "landscape",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#f5f5f5"
            },
            {
              "lightness": 20
            }
          ]
        },
        {
          "featureType": "road.highway",
          "elementType": "geometry.fill",
          "stylers": [
            {
              "color": "#ffffff"
            },
            {
              "lightness": 17
            }
          ]
        },
        {
          "featureType": "road.highway",
          "elementType": "geometry.stroke",
          "stylers": [
            {
              "color": "#ffffff"
            },
            {
              "lightness": 29
            },
            {
              "weight": 0.2
            }
          ]
        },
        {
          "featureType": "road.arterial",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#ffffff"
            },
            {
              "lightness": 18
            }
          ]
        },
        {
          "featureType": "road.local",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#ffffff"
            },
            {
              "lightness": 16
            }
          ]
        },
        {
          "featureType": "poi",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#f5f5f5"
            },
            {
              "lightness": 21
            }
          ]
        },
        {
          "featureType": "poi.park",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#dedede"
            },
            {
              "lightness": 21
            }
          ]
        },
        {
          "elementType": "labels.text.stroke",
          "stylers": [
            {
              "visibility": "on"
            },
            {
              "color": "#ffffff"
            },
            {
              "lightness": 16
            }
          ]
        },
        {
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "saturation": 36
            },
            {
              "color": "#333333"
            },
            {
              "lightness": 40
            }
          ]
        },
        {
          "elementType": "labels.icon",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "transit",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#f2f2f2"
            },
            {
              "lightness": 19
            }
          ]
        },
        {
          "featureType": "administrative",
          "elementType": "geometry.fill",
          "stylers": [
            {
              "color": "#fefefe"
            },
            {
              "lightness": 20
            }
          ]
        },
        {
          "featureType": "administrative",
          "elementType": "geometry.stroke",
          "stylers": [
            {
              "color": "#fefefe"
            },
            {
              "lightness": 17
            },
            {
              "weight": 1.2
            }
          ]
        }
      ]
    }

    const map = new google.maps.Map(this.mapElement.nativeElement, mapOptions);

    const marker = new google.maps.Marker({
      map: map,
      animation: google.maps.Animation.DROP,
      position: latLng,
    });
  }

  @ViewChild('inputFile') inputFile: ElementRef;
  onChangeInputFiles(files: FileList) {
    this.file = files.item(0);
    this.submitImages(this.file, '/site/contatoMensagem/TrabalheConosco/Arquivo')
      .then((res: any) => {
        this.file = null;
        this.mensagem.curriculo = res.body;
        this.loadingService.dismiss();
      }).catch((e) => {
        this.progress = 0;
        this.inputFile.nativeElement.value = '';
        this.loadingService.dismiss();
      });
  }

  submitImages(file: File, url: string) {
    if (!file) {
      return;
    }
    return new Promise((resolve, reject) => {
      this.siteService.postFile(file, url, 'arquivo')
        .subscribe((event: HttpProgressEvent | any) => {
          if (event.type === 4) {
            this.progress = 0;
            resolve(event);
          } else {
            this.progress = Math.round((event.loaded / event.total) * 100);
            if (isNaN(this.progress)) {
              this.progress = 100;
            }
          }
        }, err => reject(err));
    });
  }

}
