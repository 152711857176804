import { CardapioInternoComponent } from './pages/cardapio-interno/cardapio-interno.component';
import { PedidosOnlineComponent } from './pages/pedidos-online/pedidos-online.component';
import { ContatoComponent } from './pages/contato/contato.component';
import { CardapioComponent } from './pages/cardapio/cardapio.component';
import { ServicosComponent } from './pages/servicos/servicos.component';
import { EventosInternoComponent } from './pages/eventos-interno/eventos-interno.component';
import { EventosComponent } from './pages/eventos/eventos.component';
import { SobreComponent } from './pages/sobre/sobre.component';
import { HomeComponent } from './pages/home/home.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


const routes: Routes = [
  { path: '', redirectTo: '/inicio', pathMatch: 'full' },
  { path: 'inicio', component: HomeComponent },
  { path: 'sobre', component: SobreComponent },
  { path: 'eventos', component: EventosComponent },
  { path: 'eventos/:slug', component: EventosInternoComponent },
  { path: 'servicos', component: ServicosComponent },
  { path: 'cardapio', component: CardapioComponent },
  { path: 'cardapio/:slug', component: CardapioInternoComponent },
  { path: 'contato', component: ContatoComponent },
  { path: 'trabalhe-conosco', component: ContatoComponent },
  { path: 'pedidos-online', component: PedidosOnlineComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
