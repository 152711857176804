<section class="home">
    <div class="container">
        <div class="home-banner" *ngIf="!banner.linkVideo">
            <img [src]="banner.imagem" alt="Home banner">
        </div>
        <div class="home-video" *ngIf="banner.linkVideo">
            <iframe
                [src]="videoUrl"
                frameborder="0" width="100%" height="600"></iframe>
        </div>
    </div>
</section>