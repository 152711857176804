<section class="eventos">
    <div class="container">
        <div class="eventos-wrapper">
            <button class="eventos-item col50" *ngFor="let e of eventos; let i = index;"
                [style.background-image]="'url('+ e.imagemGrande +')'" [routerLink]="['/eventos', e.slug]">
                <div class="eventos-item__content">
                    <h2 class="eventos-item__title">{{e.titulo | slice:0:32}}{{e.titulo.length >= 32 ? '...' : ''}}</h2>
                    <span>ACESSE PARA SABER MAIS</span>
                </div>
            </button>
        </div>
    </div>
</section>