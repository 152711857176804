<section class="evento-interno">
    <div class="container">
        <div class="evento-interno-wrapper row">
            <div class="left col50">
                <a routerLink="/eventos" class="back-button" title="Voltar">VOLTAR</a>
                <h1 class="evento-interno__title">
                    {{evento.titulo}}
                </h1>
                <div class="evento-interno__image">
                    <img [src]="evento.imagemGrande" [alt]="evento.titulo">
                </div>
            </div>
            <div class="right col50">
                <h2>{{evento.subTitulo}}</h2>
                <p>
                    {{evento.descricao}}
                </p>
                <h4 class="form-title">
                    FAÇA SUA RESERVA
                </h4>
                <form class="form" (ngSubmit)="inscreverEvento(form)" #form="ngForm">
                    <div class="form-control">
                        <label for="email">E-mail</label>
                        <input type="text" placeholder="Digite seu e-mail" name="email" [(ngModel)]="reserva.email"
                            autocomplete="email" required>
                    </div>
                    <div class="form-control half">
                        <label for="nome">Nome</label>
                        <input type="text" placeholder="Digite seu nome" name="nome" [(ngModel)]="reserva.nome"
                            autocomplete="name" required>
                    </div>
                    <div class="form-control half">
                        <label for="telefone">Telefone</label>
                        <input type="tel" placeholder="Digite seu telefone" [mask]="phoneMask"
                            (change)="changePhoneMask($event.target.value)" name="telefone"
                            [(ngModel)]="reserva.telefone" autocomplete="tel" required>
                    </div>
                    <div class="form-control half">
                        <label for="data">Data</label>
                        <input type="tel" placeholder="00/00/0000" name="data" [(ngModel)]="reserva.data"
                            mask="00/00/0000" autocomplete="tel" required>
                    </div>
                    <div class="form-control half">
                        <label for="horario">Horário</label>
                        <input type="tel" placeholder="00:00" mask="00:00" name="horario" [(ngModel)]="reserva.horario"
                            autocomplete="tel" required>
                    </div>
                    <div class="form-control half">
                        <label for="numeroConvidados">Número de convidados</label>
                        <input type="tel" placeholder="Informe o número de convidados" name="numeroConvidados"
                            [(ngModel)]="reserva.numeroConvidados" autocomplete="tel" required>
                    </div>
                    <div class="form-control half">
                        <label for="servico">Serviço</label>
                        <select name="servico" id="servico" name="servico" [(ngModel)]="reserva.observacao" required>
                            <option [value]="undefined" hidden selected>Escolha algum destes serviços</option>
                            <option *ngFor="let s of servicos" [value]="s.titulo">{{s.titulo}}</option>
                        </select>
                    </div>
                    <button type="submit" class="btn btn-submit">ENVIAR</button>
                </form>
            </div>
        </div>
    </div>
</section>